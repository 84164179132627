import axios from 'axios';
import authHeader from './AuthHeader';

export default class SearchService {

    getConceptList(lang) {
        var code =(lang.Code === undefined)?"1":lang.Code
        // console.log ("c:", code, "-l:", lang.Code,"-")
		return axios.get('/backend/conceptList?lang='+code, { headers: authHeader() }).then(
            res => res.data
        );
    }

    getSearchList(lang, concept, search, message, radio) {
        var messages = message;
		return axios.post('/backend/searchList', { 
            search: search,
            lang: lang,
            concept: concept,
            radio: radio
        }, { 
            headers: authHeader()
        }).then((res) => {
            if (res.data == null) {
                messages.push =  ({severity: 'warn', content: 'Nincs találat!'})
            } else if (res.data.length > 99) {
                /*
                messages.push ({severity: 'info', content: 'Több mint 100 találat van! Kérjük, finomítsa a keresést!'},
                                {severity: 'info', content: '!!!Több mint 100 találat van! Kérjük, finomítsa a keresést!'}
                )
                */
                messages.push({severity: 'info', content: 'Több mint 100 találat van! Kérjük, finomítsa a keresést!'});
            }
            return [res.data, messages];
        }
        );
    }

}
