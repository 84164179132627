<template>
<div class="grid">
    <div class="col-12 md:col-12 lg:col-12">
        <div class="card">
            <h5>{{ $t("message.menu.Search", {}, { locale: $appState.locale }) }}</h5>
            <div class="formgroup-inline">
                <div class="field">
                    <label for="construction" class="p-sr-only">{{ $t("message.menu.Search", {}, { locale: $appState.locale }) }}</label>
                    <Chips v-model="$mySearch.search" id="construction" :placeholder="searchPlaceholder">
                        <template #chip="slotProps">
                            <div>
                                <span>{{slotProps.value}} - (active) </span>
                                <i class="pi pi-user-plus" style="font-size: 14px"></i>
                            </div>
                        </template>
                    </Chips>
                </div>
                <Button style="margin-top: 2px;" :label="searchPlaceholder" @click="searchClick()"></Button>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-12 lg:col-12">
        <div class="card">
            <Message v-for="msg of messages" :severity="msg.severity" :key="msg.content">{{msg.content}}</Message>
        </div>
    </div>

    <div class="col-12 md:col-12 lg:col-12" v-if="searchResults != null">
        <DataTable :value="searchResults" :paginator="true" :rows="10" ref="dt" dataKey="Conceptid" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <!-- <div style="text-align: left" v-show="currentUser()">
                <Button icon="pi pi-external-link" label="Add to shopping cart" @click="addShoppingCart($event)" />
            </div>
            --> 
            <Column field="Concept" :header="getDataType">
                <template #body="slotProps">
                    {{ propInfo(slotProps.data.concept) }}
                </template>
            </Column>
            <Column field="Years" :header="getYears">
                <template #body="slotProps">
                    <Dropdown v-show="slotProps.data.years" v-model="slotProps.data.years2" :options="dropdownYearsValuesInfo(slotProps.data.years)" name="drop" optionLabel="name" placeholder="évek" />
                </template>
            </Column>
            <Column field="Datablockid" :header="getSource"></Column>
            <Column field="Agglevel" :header="getLevel">
                <template #body="slotProps">
                    <Dropdown v-show="slotProps.data.agglevel" v-model="slotProps.data.agglevel2" :options="dropdownAggValuesInfo(slotProps.data.agglevel)" name="drop" optionLabel="name" placeholder="szint" />
                    <Button v-show="slotProps.data.agglevel" :disabled="getButtonOn(slotProps.data.years2, slotProps.data.agglevel2)" icon="pi pi-map" class="p-button-rounded p-button-success mr-2" style="margin-left: 1rem" v-on:click="mapButton(slotProps.data,slotProps.data.years2, slotProps.data.agglevel2 )" />
                </template>
            </Column>
            <template #paginatorstart>
                <Button type="button" icon="pi pi-refresh" class="p-button-text" />
            </template>
            <template #paginatorend>
                <Button type="button" icon="pi pi-cloud" class="p-button-text" />
            </template>
        </DataTable>
    </div>

</div>
</template>

<script>
import SearchService from '../service/SearchService'

export default {
    data() {
        return {
            dropdownLangValues: [{
                    name: 'Magyar',
                    code: 1
                },
                {
                    name: 'English',
                    code: 2
                }
            ],
            dropdownAggValues: [{
                    name: 'Országos',
                    code: 1
                },
                {
                    name: 'Megyei',
                    code: 2
                },
                {
                    name: 'Járás',
                    code: 3
                },
                {
                    name: 'Település',
                    code: 4
                }
            ],
            dropdownLangValue: {
                name: 'Magyar',
                code: 1
            },
            dropdownAggValue: [],
            multiconceptValue: null,
            multiconceptValues: null,
            searchResults: null,
            searchResultInfo: null,
            // constructionsearch: null,
            messages: [],
            radioValue: "and",
        }
    },
    created() {
        this.searchService = new SearchService();
        this.dropdownLangValue = {
            name: 'Magyar',
            code: 1
        };
        this.multiconceptValue = [{
            name: 'Bármilyen fogalom',
            code: 0
        }];
        if (this.$mySearch.search.length > 0) {
            this.searchClick()
        }
    },
    mounted() {
        if (this.$mySearch.search.length > 0) {
            this.searchClick()
        }
        this.searchService.getConceptList(this.dropdownLangValue).then(data => this.multiconceptValues = data);
    },
    watch: {
        '$appState.isNewThemeLoaded'(isLoaded) {
            if (isLoaded) {
                this.refreshChart();
                this.$appState.isNewThemeLoaded = false;
            }
        }
    },
    methods: {
        searchClick() {
            if (this.$mySearch.search[0].length < 3) {
                this.messages.push({
                    severity: 'warn',
                    content: 'A keresésnek minimum 3 karakteresnek kell lennie!'
                });
            } else {
                this.searchService.getSearchList(this.dropdownLangValue, this.multiconceptValue, this.$mySearch.search, this.messages, this.radioValue)
                    .then(data => {
                        this.searchResults = data[0]
                        // console.log ("DATA", data[0])
                        this.messages = data[1]
                    });
            }
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        addShoppingCart() {
            // // console.log ("add");
        },
        currentUser() {
            var u = localStorage.getItem("user")
            if (u == null || u.length == 0)
                return false;
            return true;
        },
        dropdownAggValuesInfo(list) {
            var info = [];
            if (list == undefined) {
                return info;
            }
            if (list.length > 0) {
                var myArray = list.split("|");
                for (var i = 0; i < myArray.length; i++) {
                    var name = myArray[i].split(" (")[0]
                    var code = myArray[i].split("(")[1].split(")")[0]
                    var value = myArray[i].split(" [")[1].split("]")[0] + 0
                    info.push({
                        name: name,
                        code: code,
                        value: value
                    })
                }
                info.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
                return info;
            } else {
                return [];
            }

        },
        dropdownYearsValuesInfo(list) {
            var info = [];
            if (list != undefined && list.length > 0) {
                var myArray = list.split(",");
                for (var i = 0; i < myArray.length; i++) {
                    var name = myArray[i]
                    name=name.replace(/\s/g, '')
                    info.push({
                        name: name,
                        code: name
                    })
                }
                return info;
            } else {
                return [];
            }
        },
        propInfo(info) {
            if (info != undefined && info.length > 0) {
                var myArray = info.split("|")
                var r = myArray[0]
                if (myArray.length > 1) {
                    r += " - "
                    for (var i = 1; i < myArray.length; i++) {
                        if (i > 1) {
                            r += " | "
                        }
                        r += myArray[i]
                    }
                }
                return r;
            } else {
                return "NINCS-"+info;
            }
        },
        mapButton(info, year, agglevel) {
            this.$myMapMenu.info = JSON.stringify(info);
        },
        getButtonOn(years, agglevel) {
            if (years !== undefined && agglevel !== undefined)
                return false;
            return true;
        }

    },
    computed: {
        isRTL() {
            return this.$appState.RTL;
        },
        searchPlaceholder() {
            return this.$t("message.menu.Search", {}, { locale: this.$appState.locale });
        },
        getSource() {
            return this.$t("message.search.Source", {}, { locale: this.$appState.locale });
        },
        getLevel() {
            return this.$t("message.search.Level", {}, { locale: this.$appState.locale });
        },
        getYears() {
            return this.$t("message.search.Years", {}, { locale: this.$appState.locale });
        },
        getDataType() {
            return this.$t("message.search.DataType", {}, { locale: this.$appState.locale });
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep(.p-multiselect) {
    min-width: 15rem;
}

::v-deep(.multiselect-custom-virtual-scroll .p-multiselect) {
    min-width: 20rem;
}

::v-deep(.multiselect-custom .p-multiselect-label) {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

::v-deep(.multiselect-custom .country-item.country-item-value) {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}

::v-deep(.multiselect-custom .country-item.country-item-value img.flag) {
    width: 17px;
}

.country-item {
    display: flex;
    align-items: center;
}

.country-item img.flag {
    width: 18px;
    margin-right: .5rem;
}

::v-deep(.multiselect-custom .country-placeholder) {
    padding: 0.25rem;
}

::v-deep(.p-chips .p-chips-token) {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}

::v-deep(.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token) {
    margin: 0.1rem 0.5rem 0.1rem 0;
}

.layout-wrapper .layout-main .layout-content {
    padding: 2rem;
    padding-top: 1.5rem;
    overflow: none;
}

::v-deep(.p-chips .p-chips-token) {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}
</style>
